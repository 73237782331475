@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '../src/components/common/Type/Type.css';

@font-face {
  font-family: 'WhitneySSm';
  font-weight: 400;
  src: local('WhitneySSm'),
    url('https://images.angelstudios.com/raw/upload/v1684871641/angel-funding/fonts/WhitneySS/WhitneySSm-Medium-Bas.otf');
}
@font-face {
  font-family: 'WhitneySSm';
  font-style: italic;
  font-weight: 400;
  src: local('WhitneySSm'),
    url('https://images.angelstudios.com/raw/upload/v1684871641/angel-funding/fonts/WhitneySS/WhitneySSm-MediumItalic-Bas.otf');
}
@font-face {
  font-family: 'WhitneySSm';
  font-weight: 200;
  src: local('WhitneySSm'),
    url('https://images.angelstudios.com/raw/upload/v1684871641/angel-funding/fonts/WhitneySS/WhitneySSm-Light-Bas.otf');
}
@font-face {
  font-family: 'WhitneySSm';
  font-style: italic;
  font-weight: 200;
  src: local('WhitneySSm'),
    url('https://images.angelstudios.com/raw/upload/v1684871641/angel-funding/fonts/WhitneySS/WhitneySSm-LightItalic-Bas.otf');
}
@font-face {
  font-family: 'WhitneySSm';
  font-weight: 600;
  src: local('WhitneySSm'),
    url('https://images.angelstudios.com/raw/upload/v1684871641/angel-funding/fonts/WhitneySS/WhitneySSm-Semibold-Bas.otf');
}
@font-face {
  font-family: 'WhitneySSm';
  font-style: italic;
  font-weight: 600;
  src: local('WhitneySSm'),
    url('https://images.angelstudios.com/raw/upload/v1684871641/angel-funding/fonts/WhitneySS/WhitneySSm-SemiboldItalic-Bas.otf');
}
@font-face {
  font-family: 'WhitneySSm';
  font-weight: 800;
  src: local('WhitneySSm'),
    url('https://images.angelstudios.com/raw/upload/v1684871641/angel-funding/fonts/WhitneySS/WhitneySSm-Bold-Bas.otf');
}
@font-face {
  font-family: 'WhitneySSm';
  font-style: italic;
  font-weight: 800;
  src: local('WhitneySSm'),
    url('https://images.angelstudios.com/raw/upload/v1684871641/angel-funding/fonts/WhitneySS/WhitneySSm-BoldItalic-Bas.otf');
}

:root {
  --zendesk-bottom-position: 0.75rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body,
html,
#__next {
  height: 100%;
  font-family: WhitneySsm, sans-serif;
}

#__next {
  display: flex;
  flex-direction: column;
}

/* Fix for Headless UI Dialog Bug */
/* https://github.com/tailwindlabs/headlessui/issues/762 */
html {
  padding-right: 0 !important;
}

/* Fix for Phone Input Flag Selector hidden by autocomplete */
.PhoneInputCountry {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* pointer:coarse targets touch devices */
@media (pointer: coarse) {
  .touch-hidden {
    display: none;
  }
}
